@use '@angular/material' as mat;
@import "src/scss/common.scss";

$border-color: map-get($foreground, "divider");

.mat-input-element.simple-input {
  border: 1px solid $border-color;
  border-radius:0.2em;
  display: inline-block;
  width: auto;
  padding:0.2em;
  @each $color-name, $palette in $all-colors {
    &.mat-#{$color-name} {
      @if $color-name == 'default' {
        border-color: $border-color;
      }
  
      @else {
        border-color: mat.get-color-from-palette($palette);
      }
    }
  }
}

