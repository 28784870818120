@use '@angular/material' as mat;

$header-row-height: 56px;
$row-height: 48px;
$row-horizontal-padding: 24px;

// Native HTML table structure
table.mat-table {
  border-spacing: 0;
}

tr.mat-header-row {
  height: $header-row-height;
  &.compact {
    height: 36px;
  }
}

tr.mat-row,
tr.mat-footer-row {
  height: $row-height;
  &.compact {
    height: 36px;
  }
}

th.mat-header-cell {
  text-align: left;

  [dir='rtl'] & {
    text-align: right;
  }
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;

  // Note: we use `first-of-type`/`last-of-type` here in order to prevent extra
  // elements like ripples or badges from throwing off the layout (see #11165).
  &:first-of-type {
    padding-left: $row-horizontal-padding;

    [dir='rtl'] &:not(:only-of-type) {
      padding-right: $row-horizontal-padding;
      padding-left: 0;
    }
  }

  &:last-of-type {
    padding-right: $row-horizontal-padding;

    [dir='rtl'] &:not(:only-of-type) {
      padding-right: 0;
      padding-left: $row-horizontal-padding;
    }
  }
}

.mat-table-fixed-layout {
  table-layout: fixed;
}
