@use '@angular/material' as mat;
/* stylelint-disable no-duplicate-selectors */

/**
/* Additional style elements
*/

// SECTION: color utility

@each $color-name,
$palette in $all-colors {
  .set-color {
    &.mat-#{$color-name} {
      color: mat.get-color-from-palette($palette, 'default-contrast');
      background-color: mat.get-color-from-palette($palette);
    }
  }

  .set-text-color {
    &.mat-#{$color-name} {
      color: mat.get-color-from-palette($palette, 'default');
    }
  }
}

// SECTION: Alert
.alert {
  padding: 1em;
  border: 1px dashed map-get($foreground, 'divider');
}

@each $color-name,
$palette in $all-colors {
  .alert {
    &.mat-#{$color-name} {
      color: mat.get-color-from-palette($palette);
      background:
        transparentize(mat.get-color-from-palette($palette, 'default'),
          0.9);
      border: 1px dashed mat.get-color-from-palette($palette);
    }
  }
}

// SECTION: Badge
.badge {
  display: inline-block;
  padding: 0.4em 1em;
  font-size: 1em;
  font-weight: 500;
  color: map-get($foreground, 'text');
  border-radius: 1em;

  &.small {
    font-size: 0.9em;
  }

  >.separator {
    margin: 0 0.3em;
    border-right: 1px solid #fff3;
    border-left: 1px solid #0003;
  }

  >.count {
    aspect-ratio: 1;
    margin-left: 0.2em;
    height: 1.4em;
    display: inline-block;
    text-align: center;
    line-height: 1.5em;
    border-radius: 1em;
  }
}

@each $color-name,
$palette in $app-colors {
  .badge {
    &.mat-#{$color-name} {
      color: mat.get-color-from-palette($palette, 'default-contrast');
      background-color: mat.get-color-from-palette($palette);

      >.count {
        color: mat.get-color-from-palette($palette, 'default-contrast');
        background-color: darken(mat.get-color-from-palette($palette), 10%);
      }
    }
  }
}

@each $color-name,
$palette in $theme-colors {
  .badge {
    &.mat-#{$color-name} {
      color: mat.get-color-from-palette($palette, 'default-contrast');
      background-color: mat.get-color-from-palette($palette);

      >.count {
        color: mat.get-color-from-palette($palette, 'default-contrast');
        background-color: darken(mat.get-color-from-palette($palette), 10%);
      }
    }
  }
}

.badge.disable {
  color: map-get($foreground, 'disabled-text');
  background: map-get($foreground, 'disabled-button');
}

// SECTION: Bullet

.bullet {
  margin-right: 0.4rem;
  white-space: nowrap;
}

.bullet::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.2rem;
  font-size: 10px;
  line-height: inherit;
  color: map-get($foreground, 'text');
  vertical-align: middle;
  content: '';
  background: map-get($foreground, 'divider');
  border-radius: 50%;
}

.bullet.small::before {
  font-size: 6px;
}

.bullet.sizeable::before {
  font-size: 0.7em;
}

@each $color-name,
$palette in $app-colors {
  .bullet {
    &.mat-#{$color-name}::before {
      background-color: mat.get-color-from-palette($palette);
    }
  }
}

@each $color-name,
$palette in $theme-colors {
  .bullet {
    &.mat-#{$color-name}::before {
      color: mat.get-color-from-palette($palette, 'default-contrast');
      background-color: mat.get-color-from-palette($palette);
    }
  }
}

.bullet.mat-info::before {
  background-color: orange;
}

.bullet.disable {
  &::before {
    background: map-get($foreground, 'disabled-button');
  }

  color: map-get($foreground, 'disabled-text');
}

// SECTION: Labeled card

.mat-card.labeled-card {
  margin-top: 1.4em;
  border: 2px solid transparent;
  transition: border 0.2s;

  .mat-card-title {
    position: absolute;
    top: -0.7em;
    right: auto;
    left: 1em;
    z-index: 1;
    padding: 0.2em 1em;
    font-size: 14px;
    font-weight: normal;
    color: map-get($foreground, 'text');
    background: map-get($background, 'unselected-chip');
    border-radius: 2em;
  }

  &.hover:hover {
    border: 2px solid mat.get-color-from-palette($app-primary, 500);
  }

  &.stroked {
    border: 2px solid mat.get-color-from-palette($app-primary, 500);
  }

  .mat-card-content {
    margin-top: 0.4em;
  }
}

.mat-card-content.fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mat-card.labeled-card.no-padding {
  padding: 0;

  .mat-card-content {
    margin-top: 0;
  }
}

.mat-card.labeled-card.no-padding-x {
  padding-right: 0;
  padding-left: 0;
}

@each $color-name,
$palette in $all-colors {
  .mat-card.labeled-card {
    &.mat-#{$color-name} {
      .mat-card-title {
        color: mat.get-color-from-palette($palette, '500-contrast');
        background: mat.get-color-from-palette($palette, 500);
      }

      &.hover:hover {
        border: 2px solid mat.get-color-from-palette($palette, 500);
      }

      &.stroked {
        border: 2px solid mat.get-color-from-palette($palette, 500);
      }
    }
  }
}

// Fixed card
.mat-card.fixed-card {
  padding-bottom: 2.6em;

  >.mat-card-actions:last-child {
    position: absolute;
    right: 16px;
    bottom: 0;
    left: 16px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

// SECTION: Labeled dialog

.labeled-dialog .mat-dialog-container {
  position: relative;
  padding-top: 0;
  overflow: visible;
}

.labeled-dialog .mat-dialog-title {
  position: relative;
  top: -0.6em;
  display: inline-block;
  padding: 0.2em 0.5em;
  margin-bottom: 0;
  font-size: 1.2em;
  line-height: 1em;
  color: mat.get-color-from-palette($app-primary, 'default-contrast');
  background: mat.get-color-from-palette($app-primary, 'default');
  border-radius: 0.7em;
}

@each $color-name,
$palette in $theme-colors {
  .labeled-dialog.mat-#{$color-name} .mat-dialog-title {
    color: mat.get-color-from-palette($palette, 'default-contrast');
    background: mat.get-color-from-palette($palette, 'default');
  }
}

// SECTION: List

.mat-list.stroked>.mat-list-item {
  border-bottom: 1px solid map-get($foreground, 'divider');

  &:last-child {
    border-bottom: none;
  }
}

.mat-list[auto]>.mat-list-item,
.mat-list[dense][auto]>.mat-list-item {
  height: auto;
  min-height: 40px;

  >.mat-list-item-content {
    height: auto;
    min-height: inherit;
  }
}

.mat-list>.mat-list-item.with-icon-button>.mat-list-item-content,
.mat-list[dense]>.mat-list-item.with-icon-button>.mat-list-item-content {
  padding-left: 0;
}

// SECTION: Expansion pane

.mat-accordion.stroked>.mat-expansion-panel {
  border-bottom: 1px solid map-get($foreground, 'divider');
  box-shadow: none;

  &:last-child {
    border-bottom: none;
  }
}

// SECTION: Tooltip

.mat-tooltip.multiline {
  white-space: pre-line;
}

.mat-tooltip.full {
  overflow: visible;
  text-overflow: initial;
  word-break: break-all;
}

// SECTION: Table

table .hover-actions,
.mat-table .hover-actions {
  @media #{$tabletA} {
    visibility: hidden;
  }
}

table,
.mat-table {

  >tfoot>tr:hover,
  >thead>tr:hover,
  >tbody>tr:hover {
    .hover-actions {
      visibility: visible;
    }
  }
}

// Stroked
.mat-table.stroked {
  // TODO: collapse breaks sticky, without it, border is to thick
  // border-collapse: collapse;

  >thead>.mat-header-row>.mat-header-cell,
  >tbody>.mat-row>.mat-cell,
  >tfoot>.mat-footer-row>.mat-footer-cell,
  {
  border: 1px solid map-get($foreground, "divider");
}
}

// Border
.mat-table {

  >thead>.mat-header-row>.mat-header-cell,
  >tbody>.mat-row>.mat-cell,
  >tfoot>.mat-footer-row>.mat-footer-cell,
  {

  @each $color-name,
  $palette in $all-colors {
    $color: mat.get-color-from-palette($palette, 500);

    @if $color-name =='default' {
      $color: map-get($foreground, "divider");
    }

    &.mat-#{$color-name} {
      &.border-left {
        padding-left: 0.8em;
        border-left: 1px solid $color;
      }

      &.border-right {
        padding-right: 0.8em;
        border-right: 1px solid $color;
      }

      &.border-bottom {
        border-bottom: 1px solid $color;
      }

      &.border-top {
        border-top: 1px solid $color;
      }
    }
  }
}
}

// SECTION: Link
a.link {
  color: mat.get-color-from-palette($app-primary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: mat.get-color-from-palette($app-primary, 800);
  }

  &:visited {
    color: mat.get-color-from-palette($app-primary);
    text-decoration: none;
  }
}

a.no-link {
  color: inherit;
  text-decoration: none;
}

@each $color-name,
$palette in $all-colors {
  a.link.mat-#{$color-name} {
    color: mat.get-color-from-palette($palette, 'default');

    &:hover {
      color: mat.get-color-from-palette($palette, 800);
    }

    &:visited {
      color: mat.get-color-from-palette($palette);
    }
  }
}

// SECTION: Slider

fake-form-field .mat-slider {
  height: 28px;
  padding-top: 0;

  .mat-slider-wrapper {
    top: 13px;
  }
}

// SECTION: Toggle

fake-form-field .mat-button-toggle-label-content {
  line-height: 24px;
}

// SECTION: Any

.stroke-between\:xxs {
  @media #{$large-tabletB} {
    border-top: 1px solid map-get($foreground, 'divider');

    &:last-child {
      border-bottom: 1px solid map-get($foreground, 'divider');
    }
  }
}

.stroke-between {
  border-top: 1px solid map-get($foreground, 'divider');

  &:last-child {
    border-bottom: 1px solid map-get($foreground, 'divider');
  }
}