.hidden {
  display: none;
}

.break-all {
  word-break: break-all;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.padded {
  padding-top: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
}

.padded-x {
  padding-right: 1em;
  padding-left: 1em;
}

.padded-y {
  padding-top: 1em;
  padding-bottom: 1em;
}

.margined {
  margin-top: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
}

.margined-x {
  margin-right: 1em;
  margin-left: 1em;
}

.margined-y {
  margin-top: 1em;
  margin-bottom: 1em;
}

// Used in tables
.min-width {
  width: 1px;
}
