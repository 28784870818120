@use '@angular/material' as mat;

@import 'simple-table.scss';
@import 'simple-input.scss';
@import 'theme.scss';
@import 'color-overrides.scss';
@import 'addons.scss';
@import 'utils.scss';

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  margin: 0;
}

.excelsior-logo {
  font-family: 'Lobster', cursive;
  font-size: mat.font-size($config, headline);
}

.fill {
  position: relative;
  display: flex;
  align-items: stretch;
  min-width: 100%;
  min-height: 100%;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pointable {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.form-row {
  margin-bottom: 0.6em;
}

hr {
  margin: 1em 0;
  border: 0;
  border-bottom: 1px solid map-get($foreground, 'divider');
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container-fluid {
  padding-bottom: 1em;
}

.container-fluid.with-fabs {
  @media #{$tabletA} {
    padding-right: 90px;
    padding-left: 90px;
  }
}

.center-loading {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

.container-fluid,
.container {
  .fab-buttons {
    position: fixed;
    right: 15px;
    bottom: 15px;
  }

  .fab-buttons button {
    display: block;
    margin-bottom: 15px;
  }

  .fab-buttons button:first-child {
    margin-bottom: 0;
  }
}

table td {
  vertical-align: inherit;
}

.text-muted {
  opacity: 0.6;
}

h1.page-title {
  margin: 0.4em 0.4em 0.5em;
}

.mat-table.align-top,
table.align-top {
  >tbody>tr {

    >td,
    >th {
      vertical-align: top;
    }
  }
}

.mat-table.dense {

  .mat-footer-row,
  .mat-row {
    min-height: 40px;
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: 12px;
  }
}

.table-wrapper {
  overflow: auto;

  .mat-header-cell.text-center>.mat-sort-header-container {
    justify-content: center;
  }

  .mat-header-cell.text-right>.mat-sort-header-container {
    justify-content: end;
  }

  .mat-table {
    width: 100%;

    .mat-sort-header-button {
      white-space: nowrap;
    }

    // tr.mat-header-row {
    //   height: 60px;
    // }
    >thead .mat-icon {
      font-size: 1rem;
    }

    >tfoot>tr,
    >thead>tr,
    >tbody>tr {
      >th {
        padding: 0 4px;
      }

      >th.text-center,
      >td.text-center {
        text-align: center;
      }

      >th.text-right,
      >td.text-right {
        text-align: right;
      }

      >td {
        padding: 4px;
        font-size: 1em;
        line-height: 1.25em;

        >.mat-icon {
          width: 1em;
          height: 1em;
          margin-right: 2px;
          font-size: 1.2em;
          line-height: 1em;
          text-align: center;
        }

        .small {
          font-size: 0.9em;
        }
      }

      >td.mat-cell.small {
        font-size: 0.9em;
        // color: mat.get-color-from-palette($foreground, "hint-text");
        color: mat.get-color-from-palette($foreground, 'secondary-text');
      }

      >td.mat-cell:first-of-type,
      >td.mat-footer-cell:first-of-type,
      >th.mat-header-cell:first-of-type {
        padding-left: 14px;
      }

      >td.no-data-cell {
        color: mat.get-color-from-palette($foreground, 'secondary-text');
        text-align: center;
      }

      &.compact {
        height: 36px;
      }

      &.group-row {
        height: 28px;
      }

      &.group-row>th,
      &.group-row>td,
      {
      vertical-align: bottom;
      border-bottom-width: 0;
    }

    &.collapsed-row {
      height: 0;

      >td,
      >th {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &.expandable-row>td,
    &.expandable-row>th {
      width: 0;
      border-bottom: transparent;
    }
  }
}
}

.ngx-charts-outer::after {
  display: block;
  clear: both;
  content: '';
}

.mat-card-subtitle .mat-icon {
  margin-right: 0.2em;
  margin-bottom: 0.2em;
  vertical-align: middle;
}

.text-center>.mat-sort-header-container {
  padding-left: 18px;
}

/** Overrides **/
.mat-chip-list.small .mat-chip-list-wrapper .mat-standard-chip {
  min-height: 24px;
  padding: 0.2em 0.8em;
  font-size: 0.8em;
}

.mat-grid-tile.card-tile {
  overflow: visible;

  >.mat-grid-tile-content>.mat-card,
  >.mat-grid-tile-content>.mat-card>.mat-card-content {
    width: 100%;
    height: 100%;
  }

  // background: map-get($background, "card");
  // @include mat.elevation(2);
  // padding-top: 1em;
}

.roas-low {
  color: map-get($basic-colors, 'red');
}

.roas-medium {
  color: map-get($basic-colors, 'yellow');
}

.roas-high {
  color: map-get($basic-colors, 'blue');
}

.roas-very-high {
  color: map-get($basic-colors, 'green');
}

.card-chart-wrapper .card-chart {
  &.roas-low {
    color: white;
    background-color: map-get($basic-colors, 'red');
  }

  &.roas-medium {
    color: white;
    background-color: map-get($basic-colors, 'yellow');
  }

  &.roas-high {
    color: white;
    background-color: map-get($basic-colors, 'blue');
  }

  &.roas-very-high {
    color: white;
    background-color: map-get($basic-colors, 'green');
  }
}

// SECTION: Breadcrumbs

.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 0.2em 1em;

  .current {
    padding: 0 16px;
  }

  >.item {
    &::after {
      padding: 0.2em 0.2em;
      content: '>';
    }
  }

  >.item:last-child::after {
    content: initial;
  }
}

// SECTION: Overrides

.mat-icon.sizeable {
  width: 1em;
  height: 1em;
  font-size: inherit;
}

.mat-form-field.width-auto .mat-form-field-infix {
  width: auto;
}

.mat-card.no-title-padding>.mat-card-header>.mat-card-header-text {
  margin: 0;
}

app-thumbnail.gray-image-background img {
  background-color: map-get($foreground, 'divider');
}

.mat-menu-panel.custom {
  max-width: none !important;
}

h3.form-header,
h4.form-header,
h5.form-header {
  margin-top: 0.4em;
  margin-bottom: 0.1em;
}

.popover-trigger {
  cursor: context-menu;
}