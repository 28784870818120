@use '@angular/material' as mat;
// /**
// /* Override default material components with additional colors
// */

// @each $color-name, $palette in $app-colors {
//     .set-color {
//       &.mat-#{$color-name} {
//         color: mat.get-color-from-palette($palette, "default-contrast");
//         background-color: mat.get-color-from-palette($palette);
//       }
//     }
//   }

// @each $color-name, $palette in $app-colors {
//   // SECTION: Buttons
//   .mat-flat-button,
//   .mat-raised-button,
//   .mat-fab,
//   .mat-mini-fab {
//     &.mat-#{$color-name} {
//       color: mat.get-color-from-palette($palette, 'default-contrast');
//       background-color: mat.get-color-from-palette($palette);
//       .mat-ripple-element {
//         background-color: rgba(mat.get-color-from-palette($palette, 'default-contrast'), 0.1);
//       }
//       svg .fill {
//         fill: mat.get-color-from-palette($palette, 'default-contrast');
//       }
//       svg .stroke {
//         stroke: mat.get-color-from-palette($palette, 'default-contrast');
//       }
//     }
//   }
// }

// SECTION: Toggle
@each $color-name, $palette in $all-colors {
  .mat-button-toggle-checked.mat-#{$color-name} {
    color: mat.get-color-from-palette($palette, "default-contrast");
    background-color: mat.get-color-from-palette($palette);
  }
}

// SECTION: Icon
@each $color-name, $palette in $all-colors {
  .mat-icon.mat-#{$color-name} {
    color: mat.get-color-from-palette($palette, "default");
  }
}

// SECTION: ProgressBar
@each $color-name, $palette in $app-colors {
  .mat-progress-bar.mat-#{$color-name} {
    .mat-progress-bar-primary::after {
      background-color: mat.get-color-from-palette($palette);
    }

    .mat-progress-bar-buffer {
      background-color:
        mix(
          mat.get-color-from-palette($palette),
          map-get($background, "card"),
          10%
        );
    }
    // color: mat.get-color-from-palette($palette, 'default-contrast');
  }
}
