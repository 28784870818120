// Generated in
// http://mcg.mbitson.com/#!?swpsaccent=%23ff4081&swpsprimary=%234138a0&themename=mcgtheme

// main color #ff4081

$excelsior-success: (
  50: #e8f2e4,
  100: #c6dfbb,
  200: #a0ca8e,
  300: #7ab461,
  400: #5ea43f,
  500: #41941d,
  600: #3b8c1a,
  700: #328115,
  800: #2a7711,
  900: #1c650a,
  A100: #a8ff97,
  A200: #7dff64,
  A400: #52ff31,
  A700: #3cff18,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$excelsior-info: (
  50 : #faf2e3,
  100 : #f3deb8,
  200 : #ecc989,
  300 : #e4b359,
  400 : #dea236,
  500 : #d89212,
  600 : #d48a10,
  700 : #ce7f0d,
  800 : #c8750a,
  900 : #bf6305,
  A100 : #fff2e8,
  A200 : #ffd7b5,
  A400 : #ffbb82,
  A700 : #ffad69,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$excelsior-default: (
  50: #f4f4f4,
  100: #e4e4e4,
  200: #d3d3d3,
  300: #c1c1c1,
  400: #b3b3b3,
  500: #a6a6a6,
  600: #9e9e9e,
  700: #959595,
  800: #8b8b8b,
  900: #7b7b7b,
  A100: #fff,
  A200: #fad6d6,
  A400: #ff9e9e,
  A700: #ff8585,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
